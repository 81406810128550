const awsIdCatalogo = 'lg9nqu6tkc'

export default {

    "user": [{
        "getAllURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/findAllUser/`,
        "getOneURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/`,
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/`,
        "putURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/`,
        "deletURL":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/`,
        "getOneUserByID":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/numeroID/`,
        "tokenNuevoUsuario": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/tokenNuevoUsuario/`}
    ],

    "login":[{
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/user/login/`,
        "tokenURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/auth/tokenPayload/`
    }],

    "departamentos": [{
        "postAllURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/departamentos/findAll/`,
        "getOneURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/departamentos/findOne/`,
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/departamentos/`,
        "putURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/departamentos/`,
        "deletURL":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/departamentos/`}
    ],

    "ciudades": [{
        "postAllURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/findAll/`,
        "getOneURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/findOne/`,
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/`,
        "putURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/`,
        "deletURL":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/`,
        "postAllCiudadesPorDepartamento": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/ciudades/findAll/departamento/`}
    ],
    "equipos": [{
        "postAllURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/findAllEquipos/`,
        "getOneURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/findOne/`,
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/`,
        "putURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/`,
        "deletURL":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/`,
        "postAllEquiposDiv":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/equipos/findAllEquiposDiv/`}
    ],
    "deportes": [{
        "postAllURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/deportes/findAll/`,
        "getOneURL" : `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/deportes/findOne/`,
        "postURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/deportes/`,
        "putURL": `https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/deportes/`,
        "deletURL":`https://${awsIdCatalogo}.execute-api.us-east-1.amazonaws.com/dev/deportes/`}
    ],

}



