import React, { Component } from 'react'
import ReportAfiliado from './ReportAfiliado'
import ReactToPrint from 'react-to-print';
import { Button, } from 'react-bootstrap';
import { Printer } from "react-bootstrap-icons";

class ReporteAfiliadoPadre extends Component {
    constructor(props) {
        super(props)
        this.reportRef = React.createRef()
    }
    render() {

        return (
            <div>
                <ReportAfiliado ref={this.reportRef}
                    firma={this.props.firma}
                    nombre={this.props.nombre}
                    fechaNacimiento={this.props.fechaNacimiento}
                    celular={this.props.celular}
                    direccion={this.props.direccion}
                    email={this.props.email}
                    identidad={this.props.identidad}
                    idUser={this.props.idUser}
                    fecha1={this.props.fechaFirma1}
                    division={this.props.division}
                />
                <div className={this.props.tipoUsuario == 2 ? "invisible" : null}>
                    <ReactToPrint
                        trigger={() =>



                            <div className="d-grid gap-2 m-2">
                                <Button className="mb-3" size="lg" variant="warning">
                                    <Printer /> Imprimir o Descarcar PDF
                                </Button>
                            </div>

                        }
                        content={() => this.reportRef.current}
                    />

                </div>

            </div>
        )
    }
}

export default ReporteAfiliadoPadre