import React from 'react'
import { Form, Button, Col, Row, Spinner, Card, Modal, ButtonGroup } from 'react-bootstrap';
import Webcam from "react-webcam";
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'
import { app } from '../../server/fb.js'
import db from '../../server/axios'
import { CameraFill, BodyText, ConeStriped } from "react-bootstrap-icons";
import ReporteAfiliadoPadre from '../report/ReporteAfiliadoPadre.jsx';
import ReporteHojaRepresentacionPadre from '../report/ReporteHojaRepresentacionPadre.jsx';
import ReporteClinicasParaDeportistas from '../report/ReporteClinicasParaDeportistas.jsx';
import ReporteClinicasParaDeportistasMenorEdad from '../report/ReporteClinicasParaDeportistasMenorEdad.jsx';

const imgUserNotFound = "https://firebasestorage.googleapis.com/v0/b/afhosuscripciones-df678.appspot.com/o/logos%2FnoUSerFound.png?alt=media&token=e339fd86-1d42-499c-8fe8-b5519946ae8f"
const postJugadorURl = URL.user[0].postURL
const putJugadorsURL = URL.user[0].putURL
const GetAllDepartamentos = URL.departamentos[0].postAllURL
const postAllCiudadesPorDepartamento = URL.ciudades[0].postAllCiudadesPorDepartamento
const token = (sessionStorage.getItem('token'))
const usuario = (sessionStorage.getItem('usuario'))
const usuarioNuevo = (sessionStorage.getItem('usuarioNuevo'))
const tipoUsuario = (sessionStorage.getItem('tipoUsuario'))
const numId = (sessionStorage.getItem('numeroID'))
const idURL = (sessionStorage.getItem('tipoURL'))

const CatalogoJugadorForm = (props) => {
   
 

    React.useEffect(() => {
        setDivision(1)
        setImgUserNot(imgUserNotFound)
        buscarDepartamentos()
        callAfiliadoID(numId)
       
       

        if (usuarioNuevo == 1) {
            setVisible('invisible')
        } else {
            setVisible('visible')
        }

        if (idURL == ':2') {
            setVisible2('invisible')
        } else {
            setVisible2('visible')
        }

        if (idURL == ':1') {
            setVisible3('invisible')
        } else {
            setVisible3('visible')
        }

        if (props.flag === 1) {
            restarFechas()
            const dataRowTable = props.dataTable
            
            const estadoActivo = dataRowTable.activo === 1 ? true : false
            setActivo(estadoActivo)
            setNumeroIdentidad(dataRowTable.numeroIdentidad)
            setNombreCompleto(dataRowTable.nombre)
            setDireccion(dataRowTable.direccion)
            setCelular(dataRowTable.celular)
            setEmail(dataRowTable.email)
            setObservaciones(dataRowTable.observaciones)
            setIdDepartamento(dataRowTable.idDepartamento)
            setIdCiudad(dataRowTable.idCiudad)
            setUser(dataRowTable.numeroIdentidad)
            setPassword(dataRowTable.password)
            setArchivoURL(dataRowTable.imagen ? dataRowTable.imagen : imgUserNot)
            setGenero(dataRowTable.genero)
            setFirma(dataRowTable.campoLibre1)
            setFirma2(dataRowTable.campoLibre2)
            setFechaNacimiento(dataRowTable.fechaNacimiento)
            setFechaFirma1(dataRowTable.fechaFirma1)
            setFechaFirma2(dataRowTable.fechaFirma2)
            setDivision(dataRowTable.campoLibre3)
            setEncargadoLegal(dataRowTable.campoLibre4)
            setEncargadoLegalID(dataRowTable.campoLibre5)
            getEquipos(dataRowTable.campoLibre3)
            setIdEquipoSelect(dataRowTable.idEquipo)
            setNombreDeEquipo(dataRowTable.nombreEquipo)
            setIdDeportes(dataRowTable.idDeporte)

            


        } else {

            getEquipos(1)
            setIdEquipoSelect(1)
            //setArchivoURL(imgUserNotFound)
        }



        return () => {
            setValidated({});
           
        };
    }, []);

    const [guardando, setGuardando] = React.useState(false)
    const [activo, setActivo] = React.useState(true)
    const [numeroCuenta, setNumeroCuenta] = React.useState("")
    const [numeroIdentidad, setNumeroIdentidad] = React.useState("")
    const [nombreCompleto, setNombreCompleto] = React.useState("")
    const [idDepartamento, setIdDepartamento] = React.useState(0)
    const [idCiudad, setIdCiudad] = React.useState(0)
    const [departamentos, setDepartamentos] = React.useState([])
    const [ciudad, setCiudad] = React.useState([])
    const [direccion, setDireccion] = React.useState("")
    const [celular, setCelular] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [genero, setGenero] = React.useState(0)
    const [observaciones, setObservaciones] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [user, setUser] = React.useState("")
    const [archivoURL, setArchivoURL] = React.useState(imgUserNotFound)
    const [idExiste, setIdExiste] = React.useState(false)
    const [editarIdentidad, setEditarIdentidad] = React.useState(true)
    const [show, setShow] = React.useState(false);
    const [showReport1, setShowReport1] = React.useState(false);
    const [showReport2, setShowReport2] = React.useState(false);
    const [showReport3, setShowReport3] = React.useState(false);
    const [showReport4, setShowReport4] = React.useState(false);
    const [firma, setFirma] = React.useState("");
    const [firma2, setFirma2] = React.useState("");
    const [fechaNacimiento, setFechaNacimiento] = React.useState("");
    const [fechaFirma1, setFechaFirma1] = React.useState("");
    const [fechaFirma2, setFechaFirma2] = React.useState("");
    const [imgUserNot, setImgUserNot] = React.useState("");
    const [visible, setVisible] = React.useState("");
    const [visible2, setVisible2] = React.useState("");
    const [visible3, setVisible3] = React.useState("");
    const [subiendoFoto, setSubiendoFoto] = React.useState("");
    const [equipos, setEquipos] = React.useState([]);
    const [idEquipoSelect, setIdEquipoSelect] = React.useState("");
    const [cargandoFoto, setCargandoFoto] = React.useState(false);
    const [division, setDivision] = React.useState("")
    const [encargadoLegal, setEncargadoLegal] = React.useState("");
    const [encargadoLegalID, setEncargadoLegalID] = React.useState("")
    const [idDeportes, setIdDeportes] = React.useState("")
    const [nombreDeEquipo, setNombreDeEquipo] = React.useState("")
    const [tipoAfiliado, setTipoAfiliado] = React.useState("")
    const [resultadoFecha, setResultadoFecha] = React.useState("")

    //subir foto desde archivo
    const archivoHandler = async (e) => {

        setCargandoFoto(true)
        const archivo = e.target.files[0]
        const storageRef = app.storage().ref()
        const archivoPath = storageRef.child(`Fotos/${user}`)
        await archivoPath.put(archivo)
        console.log("archivo cargado:", user) //para el nombre del archivo archivo.name
        const enlaceUrl = await archivoPath.getDownloadURL()
        setArchivoURL(enlaceUrl)
        updateFoto(tipoUsuario == 2 ? usuario : props.rowTableID, enlaceUrl)
        setCargandoFoto(false)

    }
    //subir foto desde camara

    const fotoHandler = async (e, u) => {
        setSubiendoFoto(true)
        const encodedImage = e
        const encodedImageList = encodedImage.split(",")
        const storageRef = app.storage().ref()
        const archivoPath = storageRef.child(`Fotos/${u}`)
        await archivoPath.putString(encodedImageList[1], 'base64', { contentType: 'image/jpeg' })
        //console.log("archivo cargado:", u) 
        const enlaceUrl = await archivoPath.getDownloadURL()
        setArchivoURL(enlaceUrl)
        setSubiendoFoto(false)
        updateFoto(tipoUsuario == 2 ? usuario : props.rowTableID, enlaceUrl)
        handleClose()
    }


    const handleClose = () => {
        setShow(false);

    }

    const handleShow = () => {
        setShow(true)
    };


    const handleCloseReport1 = () => {
        setShowReport1(false);
        window.location.reload(false);
    }

    const handleShowReport1 = () => {
        setShowReport1(true)
    };

    const handleCloseReport2 = () => {
        setShowReport2(false);
        window.location.reload(false);
    }

    const handleShowReport2 = () => {
        setShowReport2(true)
    };

    const handleCloseReport3 = () => {
        setShowReport3(false);
        window.location.reload(false);
    }

    const handleShowReport3 = () => {
        setShowReport3(true)
    };

    const handleCloseReport4 = () => {
        setShowReport4(false);
        window.location.reload(false);
    }

    const handleShowReport4 = () => {
        setShowReport4(true)
    };

    //tomar foto
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(

        () => {

            const imageSrc = webcamRef.current.getScreenshot();

            fotoHandler(imageSrc, user)
        },
        [webcamRef, user]
    );

    const videoConstraints = {
        width: 300,
        height: 400,
        facingMode: "user"
    }

    const updateFoto = async (id, foto) => {
        await db.updateFoto(id, foto)
    }


    const callAfiliadoID = async (e) => {
        const payloadResultado = await db.buscarAfiliadoID(e)
        payloadResultado.length === 0 ? setIdExiste(false) : setIdExiste(true)


        if (tipoUsuario == 2) {
            const dataRowTable = payloadResultado.payload
            const estadoActivo = dataRowTable.activo === 1 ? true : false
            buscarCiudades(dataRowTable[0].idDepartamento)
            setActivo(estadoActivo)
            setNumeroIdentidad(dataRowTable[0].numeroIdentidad)
            setNombreCompleto(dataRowTable[0].nombre)
            setDireccion(dataRowTable[0].direccion)
            setCelular(dataRowTable[0].celular)
            setEmail(dataRowTable[0].email)
            setObservaciones(dataRowTable[0].observaciones)
            setIdDepartamento(dataRowTable[0].idDepartamento)
            setIdCiudad(dataRowTable[0].idCiudad)
            setUser(dataRowTable[0].numeroIdentidad)
            setPassword(dataRowTable[0].password)
            setArchivoURL(dataRowTable[0].imagen ? dataRowTable[0].imagen : imgUserNot)
            setGenero(dataRowTable[0].genero)
            setFirma(dataRowTable[0].campoLibre1)
            setFirma2(dataRowTable[0].campoLibre2)
            setEditarIdentidad(true)
            setFechaNacimiento(dataRowTable[0].fechaNacimiento)
            setFechaFirma1(dataRowTable[0].fechaFirma1)
            setFechaFirma2(dataRowTable[0].fechaFirma2)
            setDivision(dataRowTable[0].campoLibre3)
            setEncargadoLegal(dataRowTable[0].campoLibre4)
            setEncargadoLegalID(dataRowTable[0].campoLibre5)
            getEquipos(dataRowTable[0].campoLibre3)
            setIdEquipoSelect(dataRowTable[0].idEquipo)
            setNombreDeEquipo(dataRowTable[0].nombreEquipo)
            setIdDeportes(dataRowTable[0].idDeporte)
            restarFechas(dataRowTable[0].fechaNacimiento)

        } else {
            //setEditarIdentidad(false)
            //setArchivoURL(imgUserNotFound)
        }

    }

    const restarFechas = async (f) =>{
          
        // Fecha inicial (04/06/1990)  
        let date1 = await new Date(props.flag === 1 ? props.dataTable.fechaNacimiento: f); 
       // let date1 = new Date("2003-02-09"); 
        // Fecha actual
        let date2 = new Date();
        // Convertir fechas a milisegundos
        let timeInMs1 = date1.getTime(); 
        let timeInMs2 = date2.getTime();
        // Restar fechas y obtener la diferencia en milisegundos
        let differenceInMs = timeInMs2 - timeInMs1;
        // Convertir milisegundos a días
        let differenceInDays = differenceInMs / 1000 / 60 / 60 / 24;
        // Convertir días a años
        let differenceInYears  = differenceInDays / 365;
       setResultadoFecha(differenceInYears)

       

 } 

    const validateFileType = (e, ev) => {
        var fileName = e
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
            archivoHandler(ev)
        } else {
            alert("Only jpg/jpeg and png files are allowed!");
        }
    }

    ////////////////// Validacion de formularios////////////////////////////////
    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {


        } else {
            if (tipoUsuario == 2) {
                update()
            } else {
                (props.flag === 0 ? insert() : update())
            }
        }
        setValidated(true);
    };

    ///////////////////////////////////////////////////////////////////////////

    const limpiarCampos = () => {

        sessionStorage.setItem('usuario', "")
        sessionStorage.setItem('tipoUsuario', "")
        sessionStorage.setItem('setYear', "")
        sessionStorage.setItem('imagen', "")
        sessionStorage.setItem('nombre', "")

        return (
            setActivo(true),
            setNumeroIdentidad(""),
            setNombreCompleto(""),
            setDireccion(""),
            setCelular(""),
            setEmail(""),
            setObservaciones(""),
            setGenero(0),
            setPassword(""),
            setUser(""),
            setEditarIdentidad(false),
            setArchivoURL(""),
            setFirma(""),
            setFirma2(""),
            setEncargadoLegal(""),
            setEncargadoLegalID("")
        )
    }

    const insert = async () => {
        setGuardando(true)
        await swal({
            title: "Esta seguro que desea ingresar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "info",
            buttons: true,
            dangerMode: false,
        })
            .then(async (save) => {


                const payloadID = await db.buscarAfiliadoID(numeroIdentidad)
                console.log(payloadID)
                if (payloadID.payload.length) {
                    swal({
                        title: "ERROR",
                        text: "Numero de identidad ya ingresado",
                        icon: "error",
                        buttons: true,
                        dangerMode: false,
                    })
                    setGuardando(false)
                }

                else if (save) {

                    axios.post(postJugadorURl,
                        {
                            activo: activo ? 1 : 0,
                            numeroCuenta: numeroCuenta,
                            numeroIdentidad: numeroIdentidad,
                            nombre: nombreCompleto,
                            idDepartamento: idDepartamento,
                            idCiudad: idCiudad,
                            direccion: direccion,
                            celular: celular,
                            genero: genero,
                            observaciones: observaciones,
                            idUsuarioMOD: usuario,
                            password: password,
                            usuario: user,
                            email: email,
                            imagen: archivoURL,
                            campoLibre1: firma,
                            fechaNacimiento: fechaNacimiento,
                            idTipoUsuario: 2,
                            idEquipo: idEquipoSelect,
                            campoLibre3: division,
                            campoLibre4: encargadoLegal,
                            campoLibre5: encargadoLegalID,
                            idDeporte: idDeportes,
                            nombreEquipo: nombreDeEquipo,
                            tipoAfiliado: idURL

                        },

                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        .then(function (response) {

                            swal({
                                title: "Cuenta creada correctamente",
                                icon: "success",
                                text: "A continuacion se cerrara su sesion, al iniciar sesion podra cambiar la fotografia del perfil y firmar los documentos para completar su afiliacion."
                            }).then(function () {
                                limpiarCampos()
                                setNumeroCuenta("")
                                setGuardando(false)
                                window.location.href = `/${idURL}`

                            });


                        })
                        .catch(function (error) {
                            swal({
                                title: "ERROR",
                                text: error,
                                icon: "info",
                                buttons: true,
                                dangerMode: false,
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }


    const update = async () => {

        setGuardando(true)

        await swal({
            title: "Esta seguro que desea modificar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    const id = (tipoUsuario == 1 ? props.rowTableID : usuario)
                    axios.put(putJugadorsURL + id,
                        {
                            activo: activo ? 1 : 0,
                            numeroCuenta: numeroCuenta,
                            nombre: nombreCompleto,
                            idDepartamento: idDepartamento,
                            idCiudad: idCiudad,
                            direccion: direccion,
                            celular: celular,
                            genero: genero,
                            observaciones: observaciones,
                            idUsuarioMOD: usuario,
                            password: password,
                            usuario: user,
                            email: email,
                            imagen: archivoURL,
                            campoLibre1: firma,
                            fechaNacimiento: fechaNacimiento,
                            idEquipo: idEquipoSelect,
                            campoLibre3: division,
                            campoLibre4: encargadoLegal,
                            campoLibre5: encargadoLegalID,
                            idDeporte: idDeportes,
                            nombreEquipo: nombreDeEquipo

                        }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(function (response) {

                            swal("Afiliado modificado correctamente!", {
                                icon: "success",
                            });
                            setGuardando(false)
                        })
                        .catch(function (error) {
                            swal("Error", {
                                icon: "error",
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }

    const buscarDepartamentos = async () => {


        await axios.post(GetAllDepartamentos, {
            nombreDepartamento: ''
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {

                const departamentosData = response.data.payload

                setDepartamentos(departamentosData)

                if (tipoUsuario == 2) {


                } else {

                    if (props.flag === 1) {
                        buscarCiudades(props.dataTable.idDepartamento)
                    } else {
                        setIdDepartamento(departamentosData[0].idDepartamento)
                        buscarCiudades(departamentosData[0].idDepartamento)
                    }
                }
            })
            .catch(function (error) {
                swal({
                    title: "ERROR",
                    text: error.message,
                    icon: "error",
                });

                console.error(error)
            })
    }

    const buscarCiudades = async (a) => {


        await axios.post(postAllCiudadesPorDepartamento, {
            idDepartamento: a
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {

                const CiudadesData = response.data.payload
                setCiudad(CiudadesData)


                if (tipoUsuario == 2) {

                } else {
                    if (props.flag === 1) {

                    } else {
                        setIdCiudad(CiudadesData[0].idCiudad)
                    }
                }
            })
            .catch(function (error) {
                swal({
                    title: "ERROR",
                    text: error.message,
                    icon: "error",
                });

                console.error(error)
            })
    }




    const renderSelectData = () => {
        return departamentos.map((item, index) => {
            return (
                <option key={index} value={item.idDepartamento} >

                    {item.nombreDepartamento}

                </option>
            )
        })
    }

    const renderSelectDataCiudad = () => {
        return ciudad.map((item, index) => {
            return (
                <option key={index} value={item.idCiudad} >

                    {item.nombreCiudad}
                </option>
            )
        })
    }

    const getEquipos = async (e) => {
        // const payloadResultado = await db.buscarEquipos(e)
        const payloadResultado = await db.buscarEquiposDiv(e)
        setEquipos(payloadResultado)
        //setIdEquipoSelect(payloadResultado[0].idEquipo)

    }

    const renderSelectDataEquipos = () => {
        return equipos.map((item, index) => {
            return (
                <option key={index} value={item.idEquipo} >

                    {item.nombreDeEquipo}

                </option>
            )
        })

    }

    return (


        <div className="col-lg-12">

            <Modal

                show={show}
                onHide={handleClose}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Camara usuario: {user}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Webcam
                        className='mx-auto d-block mb-2'
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamRef}
                        width={"90%"}
                        videoConstraints={videoConstraints}

                    />
                    <Form.Group className="mb-3">

                        <div className="d-grid gap-2">
                            <Button
                                variant="primary"
                                size="lg"
                                onClick={capture}>

                                {(subiendoFoto ? <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : null)}

                                {subiendoFoto ? " Tomando Fotografia..." : " Tomar Fotografia"}
                            </Button>

                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showReport1}
                onHide={handleCloseReport1}
                size="lg"
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Hoja de Afiliacion</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                    <ReporteAfiliadoPadre
                        nombre={nombreCompleto}
                        fechaNacimiento={fechaNacimiento}
                        celular={celular}
                        direccion={direccion}
                        firma={firma}
                        email={email}
                        identidad={numeroIdentidad}
                        idUser={tipoUsuario == 2 ? usuario : props.rowTableID}
                        fechaFirma1={fechaFirma1}
                        tipoUsuario={tipoUsuario}
                        division={division}
                        idURL={idURL}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReport1}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showReport2}
                onHide={handleCloseReport2}
                size="lg"
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Hoja de Representacion Menor de Edad</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ReporteHojaRepresentacionPadre
                        nombre={nombreCompleto}
                        fechaNacimiento={fechaNacimiento}
                        celular={celular}
                        direccion={direccion}
                        firma2={firma2}
                        email={email}
                        identidad={numeroIdentidad}
                        idUser={tipoUsuario == 2 ? usuario : props.rowTableID}
                        fechaFirma2={fechaFirma2}
                        tipoUsuario={tipoUsuario}
                        division={division}
                        encargadoLegal={encargadoLegal}
                        encargadoLegalID={encargadoLegalID}
                        idURL={idURL}
                    />


                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseReport2}>
                        Cerrar
                    </Button>



                </Modal.Footer>
            </Modal>

            <Modal
                show={showReport3}
                onHide={handleCloseReport3}
                size="lg"
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Hoja de Representacion Clicinas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ReporteClinicasParaDeportistas
                        nombre={nombreCompleto}
                        fechaNacimiento={fechaNacimiento}
                        celular={celular}
                        direccion={direccion}
                        firma={firma}
                        email={email}
                        identidad={numeroIdentidad}
                        idUser={tipoUsuario == 2 ? usuario : props.rowTableID}
                        fechaFirma={fechaFirma1}
                        tipoUsuario={tipoUsuario}
                        division={division}
                        encargadoLegal={encargadoLegal}
                        encargadoLegalID={encargadoLegalID}
                        idURL={idURL}
                    />


                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseReport2}>
                        Cerrar
                    </Button>



                </Modal.Footer>
            </Modal>

            <Modal
                show={showReport4}
                onHide={handleCloseReport4}
                size="lg"
                backdrop="static"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Hoja de Representacion Menor de Edad</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ReporteClinicasParaDeportistasMenorEdad
                        nombre={nombreCompleto}
                        fechaNacimiento={fechaNacimiento}
                        celular={celular}
                        direccion={direccion}
                        firma={firma2}
                        email={email}
                        identidad={numeroIdentidad}
                        idUser={tipoUsuario == 2 ? usuario : props.rowTableID}
                        fechaFirma={fechaFirma2}
                        tipoUsuario={tipoUsuario}
                        division={division}
                        encargadoLegal={encargadoLegal}
                        encargadoLegalID={encargadoLegalID}
                        idURL={idURL}
                    />


                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseReport2}>
                        Cerrar
                    </Button>



                </Modal.Footer>
            </Modal>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="JugadorCheck">
                        <Form.Check
                            type="checkbox"
                            label="Activo"
                            defaultChecked={true}
                            onChange={e => setActivo(e.target.value)} />

                    </Form.Group>

                    <Form.Group className=" mb-3" controlId="JugadorCheck" as={Col} md="12" lg="12" >

                        <Row>
                            <Col >
                                <Card className='mx-auto d-block' border="secondary" style={{ width: '18rem' }}>
                                    <Card.Header>Fotografia</Card.Header>
                                    <Card.Body>
                                        <Card.Img className='rounded mx-auto d-block'
                                            style={{ maxWidth: '10rem' }}
                                            variant="top"
                                            src={archivoURL} />
                                    </Card.Body>
                                </Card></Col>
                            <Col>  <div className={visible} >
                                <Form.Label>
                                    {cargandoFoto ?

                                        <span>cargando
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /></span>

                                        : 'Seleccionar una imagen desde archivo o tomar una fotografia'
                                    }
                                </Form.Label>
                                <Form.Group controlId="formFile" className="mb-3" as={Col} md="12" lg="12" >
                                    <Form.Control
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={e => validateFileType(e.target.value, e)}
                                    />

                                </Form.Group>



                                <div className="d-grid gap-2">
                                    <Button variant="primary" size="lg"
                                        onClick={() => [handleShow()]}>

                                        <CameraFill /> Tomar Fotografia
                                    </Button>

                                </div>

                            </div></Col>
                        </Row>


                    </Form.Group>



                    <Form.Group as={Col} md="12" lg="6" controlId="JugadorIdentidad">
                        <Form.Label>Numero de Identidad</Form.Label>
                        <Form.Control
                            type="text"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            placeholder="Ejemplo: 0801199000000"
                            required
                            onChange={
                                e => [setNumeroIdentidad(e.target.value), setUser(e.target.value)]
                            }
                            value={numeroIdentidad}
                            autoComplete="off"
                            maxLength={13}
                            disabled={props.flag === 1 || tipoUsuario == 2 ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar Numero de identidad
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="nombreCompleto">
                        <Form.Label>Nombre Completo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            onChange={e => setNombreCompleto(e.target.value)}
                            value={nombreCompleto}

                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar Nombre Completo
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="fechaNacimiento">
                        <Form.Label>Ingrese Fecha de Nacimiento</Form.Label>

                        <Form.Control
                            type="date"
                            placeholder=""
                            required
                            onChange={e => [setFechaNacimiento(e.target.value), restarFechas(fechaNacimiento)]}
                            value={fechaNacimiento}
                        />

                        <Form.Control.Feedback type="invalid">
                            Ingresar Fecha
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar contraseña
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="12" controlId="celular">
                        <Form.Label>Numero de Celular</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            onChange={e => setCelular(e.target.value)}
                            value={celular}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar numero de celular
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="division">
                        <Form.Label>Division</Form.Label>

                        <Form.Select
                            aria-label="divisionSelect"
                            value={division}
                            onChange={e => [setDivision(e.target.value), getEquipos(e.target.value)]}
                        >

                            <option value={1}>Primera</option>
                            <option value={2}>Segunda</option>
                            <option value={3}>Reservas</option>
                            <option value={4}>Amateur</option>
                            <option value={5}>Femenino</option>

                        </Form.Select>
                    </Form.Group>

                    <div className={visible3} >


                        <Form.Group as={Col} md="12" lg="6" controlId="deportes">
                            <Form.Label>Deportes</Form.Label>

                            <Form.Select
                                aria-label="deportesSelect"
                                value={idDeportes}
                                onChange={e => [setIdDeportes(e.target.value)]}
                            >
                                <option value={1}>Futbol</option>
                                <option value={2}>Basketball</option>
                                <option value={3}>Boxeo</option>
                                <option value={4}>Vóleyball</option>
                                <option value={5}>Atletismo</option>
                                <option value={6}>Natación</option>
                                <option value={7}>Ciclismo</option>
                                <option value={8}>Futbol Americano</option>
                                <option value={9}>Tenis</option>
                                <option value={10}>Beisbol</option>
                                <option value={11}>Artistas marciales</option>
                                <option value={12}>Gimnasia</option>
                                <option value={13}>Esgrima</option>
                                <option value={14}>Lucha libre</option>
                                <option value={15}>Rugby</option>
                                <option value={16}>Otro</option>

                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="12" lg="12" controlId="email">
                            <Form.Label>Nombre de Equipo</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                onChange={e => setNombreDeEquipo(e.target.value)}
                                value={nombreDeEquipo}
                            />

                        </Form.Group>
                    </div>
                    <div className={visible2} >

                        <Form.Group as={Col} md="12" lg="6" controlId="selectEquipo" className="mb-2">
                            <Form.Label>Equipo</Form.Label>

                            <Form.Select
                                aria-label="equipoSelect"
                                value={idEquipoSelect}
                                onChange={e => [setIdEquipoSelect(e.target.value)]}>
                                {renderSelectDataEquipos()}

                            </Form.Select>
                        </Form.Group>
                    </div>

                    <Form.Group as={Col} md="12" lg="12" controlId="email">
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar EMAIL
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="selectDepartamento" className="mb-2">
                        <Form.Label>Departamento</Form.Label>

                        <Form.Select
                            aria-label="departamentoSelect"
                            value={idDepartamento}
                            onChange={e => [setIdDepartamento(e.target.value), buscarCiudades(e.target.value)]}>

                            {renderSelectData()}

                        </Form.Select>

                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="selectCiudad" className="mb-2">
                        <Form.Label>Municipio</Form.Label>

                        <Form.Select
                            aria-label="ciudadSelect"
                            value={idCiudad}
                            onChange={e => setIdCiudad(e.target.value)}
                        >

                            {renderSelectDataCiudad()}

                        </Form.Select>

                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="12" controlId="JugadorDireccion">
                        <Form.Label>Direccion</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder=""
                            required
                            onChange={e => setDireccion(e.target.value)}
                            value={direccion}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar direccion
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="12" controlId="genero">
                        <Form.Label>Genero</Form.Label>

                        <Form.Select
                            aria-label="generoSelect"
                            value={genero}
                            onChange={e => setGenero(e.target.value)}
                        >
                            <option>Seleccione un genero</option>
                            <option value={1}>Hombre</option>
                            <option value={2}>Mujer</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="6" controlId="encargadoLegalID">
                        <Form.Label>Numero identidad encargado legal</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={resultadoFecha >= 21.01369 ?  "Campo solo para jugadores menores de 21 años":""}
                            onChange={e => setEncargadoLegalID(e.target.value)}
                            value={encargadoLegalID}
                            disabled={resultadoFecha >= 21.01369 ? true : false}
                        />

                    </Form.Group>


                    <Form.Group as={Col} md="12" lg="6" controlId="encargadoLegal">
                        <Form.Label>Nombre Encargado Legal</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={resultadoFecha >= 21.01369 ? "Campo solo para jugadores menores de 21 años":""}
                            onChange={e => setEncargadoLegal(e.target.value)}
                            value={encargadoLegal}
                            disabled={resultadoFecha >= 21.01369 ? true : false}
                        />

                    </Form.Group>


                    <Form.Group as={Col} md="12" lg="12" controlId="observaciones">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control as="textarea"
                            placeholder=""
                            onChange={e => setObservaciones(e.target.value)}
                            value={observaciones}
                        />
                    </Form.Group>

                    <div className={visible} >

                        <div className="d-grid gap-2 mt-3">

                            <Button
                                onClick={() => { (idURL === ':2' ? handleShowReport3() : handleShowReport1()) }}
                                size="lg"
                                variant="secondary">
                                <BodyText />  {(idURL === ':2' ? 'Contrato Clinicas AFHO' : 'Hoja de Afiliacion')}
                            </Button>

                            <Button
                                onClick={() => { (idURL === ':2' ? handleShowReport4() : handleShowReport2()) }}
                                size="lg"
                                variant="secondary"
                                disabled={resultadoFecha >= 21.01369 ? true : false}>
                                    
                                <BodyText /> {(idURL === ':2' ? 'Contrato Clinicas AFHO Menor de edad' : 'Hoja de Afiliacion')}
                            </Button>
                        </div>
                    </div>

                </Row>

                <div className="d-grid gap-2">
                    {!numeroIdentidad && props.flag === 1 ?
                        <Button size="lg" variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Cargando...
                        </Button> : guardando ?

                            <Button size="lg" variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {(props.flag === 0 ? " Guardando..." : " Modificando...")}
                            </Button> : <Button
                                type="submit" size="lg"
                                variant={(props.flag === 0 ? "primary" : "warning")}>
                                {(props.flag === 0 ? "Guardar Afiliado" : "Modificar Afiliado")}
                            </Button>}
                </div>
            </Form>
        </div>
    )
}

export default CatalogoJugadorForm
