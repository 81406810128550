import React from 'react'
import { Table, Modal, Button } from 'react-bootstrap';
import CatalogoDeporteForm from './CatalogoDeporteForm';

const CatalogoDeporteTabla = (props) => {
    React.useEffect(() => {
        setDataTable(props.DeportesS)

    }, []);


    const [dataTable, setDataTable] = React.useState([])
    const [show, setShow] = React.useState(false);
    const [rowTableID, setRowTableID] = React.useState();
    const [indexTable, setIndexTable] = React.useState();

    const handleClose = () => {
        props.buscarDeportesS("")
        setShow(false);

    }

    const handleShow = () => {
        setShow(true)
    };

    const renderTableData = () => {
        return dataTable.map((item, index) => {

            return (
                <tr key={index} id={item.id} >
                    <td >{index + 1}</td>
                    <td>{item.idDeporte}</td>
                    <td>{item.nombreDeporte}</td>
                    <td>
                        <p>
                            <Button variant="primary" onClick={() => [setRowTableID(item.idDeporte), handleShow(), setIndexTable(index)]}>
                                Editar
                            </Button>
                        </p>
                    </td>
                </tr>
            )
        })
    }


    return (

        <div className="container overflow-auto max-vh-100">

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Deporte</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <CatalogoDeporteForm
                        flag={1}
                        rowTableID={rowTableID}
                        dataTable={dataTable[indexTable]}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>



            <Table striped bordered hover variant="table-striped"

            >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>id</th>
                        <th>Deporte</th>
                        <th>Editar</th>

                    </tr>
                </thead>

                <tbody>

                    {renderTableData()}

                </tbody>

            </Table>
        </div>
    )
}

export default CatalogoDeporteTabla
