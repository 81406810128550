import React from 'react'
import CatalogoJugadorForm from '../components/Catalogo/CatalogoJugadorForm.jsx';

const Principal = () => {


    return (

  <div className="container">
    <CatalogoJugadorForm
    flag={0}/>
  </div>
     
    )
}

export default Principal
