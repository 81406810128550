import React from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Switch, Route, NavLink, Spinner } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Principal from '../pages/Principal';
import Catalogo from '../pages/Catalogo';
import Equipo from '../pages/Equipo';
import Deporte from '../pages/Deporte';
import nuevoUsuario from '../pages/NuevoUsuario';

import './styles/menuDeLado.css'
import { Collapse } from 'react-bootstrap';
import swal from 'sweetalert';
import axios from 'axios';
import URL from '../server/endPoints.js'

const urlTokenPayload = URL.login[0].tokenURL
const token = (sessionStorage.getItem('token'))
const usuarioNuevo = (sessionStorage.getItem('usuarioNuevo'))
const imagen = (sessionStorage.getItem('imagen'))
const nombre = (sessionStorage.getItem('nombre'))
const tipoUsuario = (sessionStorage.getItem('tipoUsuario'))
const tipoURL = (sessionStorage.getItem('tipoURL'))




const MenuDeLado = () => {

    React.useEffect(() => {
        buildNombre()
    })

    if (!token) {
        swal({
            title: "INICIE SESION!",
            text: `SESION CERRADA!`,
            icon: "error",
            button: "Aceptar",
        })
            .then(() => {
                {
                    window.location.href = `/${tipoURL}`
                }
            });
    } else {

        axios.post(urlTokenPayload,
            {
                payload: "Bearer " + token
            })
            .then(function (response) {
                return
            })
            .catch(function (error) {

                swal({
                    title: "INICIE SESION!",
                    text: `SESION CERRADA!`,
                    icon: "error",
                    button: "Aceptar",
                })
                    .then(() => {
                        {
                            window.location.href = `/${tipoURL}`
                        }
                    });
                console.log(error)
            })
    }

    const [openControlAcademico, setOpenControlAcademico] = React.useState(true);


    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [nombreCompleto, setNombreCompleto] = React.useState("false")

    const buildNombre = () => {

        const nombreSplit = nombre.split(" ")
       
  if (nombreSplit.length === 1) {
            setNombreCompleto(nombreSplit[0])
        } else if (nombreSplit.length === 2) {
            setNombreCompleto(nombreSplit[0] + ' ' + nombreSplit[1])
        } else if (nombreSplit.length >= 3) {
            setNombreCompleto(nombreSplit[0] + ' ' + nombreSplit[2])
        }
    }

    return (

        <BrowserRouter>
            <div>
                <div className="container-fluid"   >
                    <div className="row" >
                        <div className='h1 d-md-none bi bi-list' onClick={handleShow}></div>
                        <div className={`d-none d-md-block col-auto col-md-3 col-xl-3 px-sm-2 px-0 bg-dark shadow-lg overflow-auto vh-100`} >
                            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                                <a href="" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                                    <span className="fs-5 d-none d-sm-inline">Menu Principal</span>
                                </a>

                                <div className="dropdown pb-4">
                                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={imagen} width="50" height="50" className="rounded-circle  mx-auto" />
                                        <span className="d-none d-sm-inline mx-1">{usuarioNuevo == 1 ? " Nuevo Usuario" : nombreCompleto} </span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">

                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li><a className="dropdown-item" href= {`/${tipoURL}`}>Cerrar sesión</a></li>
                                    </ul>
                                </div>
                                <div className="d-grid gap-2 col-12">
                                    <hr />
                                    <span
                                        className="btn btn-outline-light buttonB dropdown-toggle"
                                        onClick={() => setOpenControlAcademico(!openControlAcademico)}
                                        aria-controls="collapse-ControlAcademico"
                                        aria-expanded={openControlAcademico}

                                    >AFHO
                                    </span>
                                    <Collapse in={openControlAcademico}>
                                        <div id="collapse-ControlAcademico">
                                            <div className="d-grid gap-2">
                                                {usuarioNuevo === 2 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to='/nuevoUsuario'><span className="ms-3 d-none d-sm-inline">Afiliado</span> </NavLink> : null}
                                                {tipoUsuario == 1 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-building" activeClassName="active" to='/Jugadores'><span className="ms-3 d-none d-sm-inline">Jugadores</span> </NavLink> : null}
                                                {tipoUsuario == 1 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to='/Equipo'><span className="ms-3 d-none d-sm-inline">Equipos</span> </NavLink> : null}
                                                {tipoUsuario == 1 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-bicycle" activeClassName="active" to='/Deporte'><span className="ms-3 d-none d-sm-inline">Deportes</span> </NavLink> : null}
                                                {tipoUsuario == 2 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to='/nuevoUsuario'><span className="ms-3 d-none d-sm-inline">Afiliado</span> </NavLink> : null}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="col py-3 overflow-auto vh-100">
                            <Switch>
                                <Route path="/Principal" component={Principal} />
                                <Route path="/Jugadores" component={Catalogo} />
                                <Route path="/Equipo" component={Equipo} />
                                <Route path="/Deporte" component={Deporte} />
                                <Route path="/nuevoUsuario" component={nuevoUsuario} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} className="bg-dark shadow-lg col-auto">
                <br />
                <img src={imagen} width="140" height="140" className="rounded-circle  mx-auto" />
                <Offcanvas.Header >

                    <div className="dropdown pb-4">
                        <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

                            <span className=" d-sm-inline mx-1">{usuarioNuevo == 1 ? " Nuevo Usuario" : nombreCompleto} </span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">

                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li><a className="dropdown-item" href= {`/${tipoURL}`}>Cerrar sesión</a></li>
                        </ul>
                    </div>

                </Offcanvas.Header>

                <Offcanvas.Body>


                    <div className="d-grid gap-2">
                        <hr />
                        <span
                            className="btn btn-outline-light buttonB dropdown-toggle"
                            onClick={() => setOpenControlAcademico(!openControlAcademico)}
                            aria-controls="collapse-ControlAcademico"
                            aria-expanded={openControlAcademico}

                        > AFHO
                        </span>

                        <Collapse in={openControlAcademico}>
                            <div id="collapse-ControlAcademico">
                                <div className="d-grid gap-2">
                                    {usuarioNuevo === "1" ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to= {`/nuevoUsuario/${tipoURL}`}><span className="ms-3  d-sm-inline">Afiliado</span> </NavLink> : null}
                                    {tipoUsuario == 1 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-building" activeClassName="active" to='/Jugadores'><span className="ms-3  d-sm-inline">Jugadores</span> </NavLink> : null}
                                    {tipoUsuario == 1 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to='/Equipo'><span className="ms-3 d-sm-inline">Equipos</span> </NavLink> : null}
                                    {tipoUsuario == 2 ? <NavLink className="btn btn-outline-secondary btn-sm buttonB bi-calendar" activeClassName="active" to={`/nuevoUsuario/${tipoURL}`}><span className="ms-3  d-sm-inline">Afiliado</span> </NavLink> : null}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </BrowserRouter>
    )
}
export default MenuDeLado
