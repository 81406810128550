import React from 'react'
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { debounce } from "debounce";
import CatalogoDeporteForm from './CatalogoDeporteForm'
import CatalogoDeporteTabla from './CatalogoDeporteTabla';
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'



const getAllDeportesURL = URL.deportes[0].postAllURL

const token = (sessionStorage.getItem('token'))

const CatalogoDeporte = () => {

    const [Deportes, setDeportes] = React.useState([])
    const [sinResultado, setSinResultado] = React.useState(false)

    const buscarDeportes = async (e) => {
        setSinResultado(false)
        setDeportes([])

        await axios.post(getAllDeportesURL, {
            nombreDeporte: e
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {
                
                const DeportesData = response.data.payload

                !DeportesData.length ? setSinResultado(true) :
                    setDeportes(DeportesData)
                
            })
            .catch(function (error) {
                swal({
                    title: "ERROR",
                    text: error.message,
                    icon: "error",
                });

                console.error(error)
            })
    }

    return (

        <div>
            <Tabs
                defaultActiveKey="cDeportes"
                onSelect=
                {
                    (eventKey) => {
                        if (eventKey === "eDeportes") {
                           buscarDeportes("")
                        }
                    }

                }
                id="uncontrolled-tab-example"
                className="mb-3"
                transition={true}>

                <Tab eventKey="cDeportes" title="Crear Equipo" >
             
                    <CatalogoDeporteForm
                        flag={0}
                    />

                </Tab>
                <Tab

                    eventKey="eDeportes"
                    title="Buscar y Editar"

                >
                    <div className="container col-12">

                        <div className="input-group mb-3 mt-2">
                            <span
                                className="input-group-text btn btn-dark bi-search"
                                type="button"> Buscar </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar Deportes"
                                aria-label="buscarDeportes"
                                aria-describedby="basic-addon1"
                                onChange={debounce(e =>
                                    buscarDeportes(e.target.value)
                                    , 1000)
                                }
                            />

                        </div>
                    </div>

                    {sinResultado ? <div className="alert alert-danger" role="alert">
                        No se encontraron datos
                    </div> : !Deportes.length ?

                        <Spinner animation="border" variant="primary" />
                        :

                        <CatalogoDeporteTabla
                            DeportesS={Deportes}
                            buscarDeportesS={buscarDeportes}

                        />
                    }

                </Tab>

            </Tabs>

        </div>
    )
}

export default CatalogoDeporte
