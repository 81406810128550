import React, { useRef } from 'react'
import { Render } from 'react-dom'
import { Container, Button, Col, Row, Spinner, Card, Modal,Form } from 'react-bootstrap';
import { Border, PenFill } from "react-bootstrap-icons";
import CanvasDraw from 'react-canvas-draw'
import db from '../../server/axios'
import swal from 'sweetalert';

const ReportAfiliado = React.forwardRef((p, ref) => {
  const existeFirma = p.firma

  React.useEffect(() => {

    if (existeFirma) {
      firmaDrawDocumento.current.loadSaveData(p.firma)
      setFechaFirma(p.fecha1)
      setVisible('invisible')

    } else {
      setVisible('visible')

      fecha()
    }


  })
  const [show, setShow] = React.useState(false);
  const [firma, setFirma] = React.useState("");
  const [fechaFirma, setFechaFirma] = React.useState("");
  const [cargando, setCargando] = React.useState(false);
  const [visible, setVisible] = React.useState("");

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true)
  };

  const firmaDraw = useRef(null)
  const firmaDrawDocumento = useRef(null)

  const handleClickGuardar = async () => {

    swal({
      title: "Esta Seguro que desea guardar?",
      text: "Una vez guardado no se podra modificar el archivo.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (save) => {
        if (save) {
          setCargando(true)
          const data = firmaDraw.current.getSaveData();
          setFirma(data)

          await updateFirma(p.idUser, data, fechaFirma)
          firmaDrawDocumento.current.loadSaveData(data)
          setCargando(false)
          swal("Documento Firmado Correctamente!", {
            icon: "success",
          });
          handleClose()
        }
      });
  }

  const updateFirma = async (id, data, date) => {

    await db.actualizarFirma(id, data, date)

  }


  const clear = () => {
    firmaDraw.current.clear()
  }


  const fecha = () => {

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaFirma(date)
  }
  return (

    <div>
      <Container className='col-lg-11 mx-auto' ref={ref}>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>firma</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <CanvasDraw
              style={{ border: "solid" }}
              brushRadius={1}
              brushColor={"#000000"}
              ref={firmaDraw}
              canvasWidth={300}
              canvasHeight={300}
              lazyRadius={0}
            />
          </Modal.Body>
          <Modal.Footer>

            <Button

              variant="success"
              onClick={handleClickGuardar}>

              {(cargando ? <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : null)}

              {cargando ? " Guardando..." : " Guardar"}

            </Button>

            <Button variant="warning" onClick={clear}>
              Limpiar
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>


        <style type="text/css" media="print">
          {"\
            @page { size: Letter; margin: 50px 50px 50px 50px}\
      "}</style>

        <Row className='align-middle'>
          <Col>
            <Card
              style={{ width: '150px', height: '150px', border: "none" }}

            >
              <Card.Img variant="top" src={"https://firebasestorage.googleapis.com/v0/b/afhosuscripciones-df678.appspot.com/o/Documentos%2FAFHO-01.png?alt=media&token=bd19ec6e-d641-41fb-85f3-6de2cd7742dc"} />
            </Card>
          </Col>

          <Col className="text-center col-lg-6"><h4>ASOCIACION DE FUTBOLISTAS DE HONDURAS (AFHO)</h4></Col>
          <Col>

            <span>A <strong>FIFPRO</strong>  MEMBER</span>

          </Col>

        </Row>

        <Row className="">
          <Col>
            <p className='mt-2'>Estimados Señores:</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> Yo <strong>{p.nombre}</strong>, futbolista {p.division>2? 'amateur':'profesional'} , con cedula de identidad numero: <strong>{p.identidad}</strong>, atento digo:</p>
            <p className='text-justify' style={{ textAlign: "justify" }}>Que por medio de la presente me permito confirmar que soy asociado de la Asociación de Futbolistas de Honduras (AFHO), con personalidad jurídica No. 756-2012, inscrita por el Estado de Honduras en la fecha 20 de agosto del 2012 y de la cual el señor Carlos Alvarado, es el presidente con facultades de apoderado generalísimo sin límites de suma.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}>Así mismo en virtud de ser la única asociación del país, me permito autorizarles para que en mi nombre me representen en cualquier acto necesario para procurar el bienestar de mis derechos tanto laborales como comerciales.</p>
          </Col>
        </Row>

        <Row className="mb-3">

          <Col className="col-lg-12 border" >

            <h3> Datos Pesonales</h3>

            <span><strong>Fecha de Nacimiento: </strong>  {p.fechaNacimiento}</span>
            <br />
            <span><strong>Correo Electrónico: </strong>  {p.email}</span>
            <br />
            <span><strong>Teléfono Celular: </strong>  {p.celular}</span>
            <br />
            <span><strong>Dirección: </strong>  {p.direccion}</span>

          </Col>

        </Row>
        <Row className='mt-0'>
          <Col></Col>
          <Col>

            <CanvasDraw
              className='ms-5'
              ref={firmaDrawDocumento}
              canvasWidth={280}
              canvasHeight={280}
              disabled={true}
              hideGrid={true}
            />
          </Col>
        </Row>

        <Row className=''>
          <Col className="text-center" > <strong>Fecha:</strong>  <u>{fechaFirma}</u> </Col>
          <Col className="text-center" >
            <strong>Firma:</strong> ___________</Col>
        </Row>

        

      </Container>



      <div className="d-grid gap-2 m-2">
        <Button className={visible} size="lg" variant="dark" onClick={() => [handleShow()]}>
          <PenFill /> Firmar
        </Button>
     
      </div>

    </div>

  )
}
)
export default ReportAfiliado