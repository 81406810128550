import React from 'react'
import CatalogoJugador from '../components/Catalogo/CatalogoJugador.jsx';

const Catalogo = () => {
    React.useEffect(() => {
        const tipoUsuario = (sessionStorage.getItem('tipoUsuario'))

        if(tipoUsuario==2){
            alert("no tienes permisos para estar en este sitio")
            window.location.href = './'
        }
    })
    return (
        <div>
            <CatalogoJugador/>
        </div>
    )
}
export default Catalogo
