import React from 'react'
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { debounce } from "debounce";
import CatalogoJugadorForm from './CatalogoJugadorForm'
import CatalogoJugadorTabla from './CatalogoJugadorTabla';

import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'

const GetAllJugadoresURL = URL.user[0].getAllURL
const token = (sessionStorage.getItem('token'))

const CatalogoJugador = () => {

    const [Jugadores, setJugadores] = React.useState([])
    const [sinResultado, setSinResultado] = React.useState(false)

    const buscarJugadores = async (e) => {
        setSinResultado(false)
        setJugadores([])

        await axios.post(GetAllJugadoresURL, {
            nombre: e
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {

                const JugadoresData = response.data.payload

                !JugadoresData.length ? setSinResultado(true) :
                    setJugadores(JugadoresData)

            })
            .catch(function (error) {
                swal({
                    title: "ERROR",
                    text: error.message,
                    icon: "error",
                });

                console.error(error)
            })
    }

    return (

        <div>
            <Tabs
                defaultActiveKey="cJugadores"
                onSelect=
                {
                    (eventKey) => {
                        if (eventKey === "eJugadores") {
                            buscarJugadores("")
                        }
                    }
                }
                id="uncontrolled-tab-example"
                className="mb-3"
                transition={true}>

                <Tab eventKey="cJugadores" title="Crear Afiliado" >

                    <CatalogoJugadorForm
                        flag={0}
                    />

                </Tab>
                <Tab
                    eventKey="eJugadores"
                    title="Buscar y Editar"
                >
                    <div className="container col-12">
                        <div className="input-group mb-3 mt-2">
                            <span
                                className="input-group-text btn btn-dark bi-search"
                                type="button"> Buscar </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar Jugadores"
                                aria-label="buscarJugadores"
                                aria-describedby="basic-addon1"
                                onChange={debounce(e =>
                                    buscarJugadores(e.target.value)
                                    , 1000)
                                }
                            />

                        </div>
                    </div>

                    {sinResultado ? <div className="alert alert-danger" role="alert">
                        No se encontraron datos
                    </div> : !Jugadores.length ?
                        <Spinner animation="border" variant="primary" />
                        :
                        <CatalogoJugadorTabla
                            JugadoresS={Jugadores}
                            buscarJugadoresS={buscarJugadores}
                        />
                    }
                </Tab>

            </Tabs>

        </div>
    )
}

export default CatalogoJugador
