import axios from 'axios';
import URL from '../server/endPoints.js'

const token = (sessionStorage.getItem('token'))
const usuario = (sessionStorage.getItem('usuario'))

const getAllJugadores = URL.user[0].getAllURL
const getAllEquipos = URL.equipos[0].postAllURL
const getOneUserByID = URL.user[0].getOneUserByID
const updateFirma = URL.user[0].putURL
const tokenNuevoUsuarioURL =URL.user[0].tokenNuevoUsuario
const getAllEquiposDiv = URL.equipos[0].postAllEquiposDiv

export default {

    buscarJugadores: async (e) => {

        try {
            const resp = await axios.post(getAllJugadores, {
                    nombre: e
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },
    
    buscarAfiliadoID: async (e) => {

        try {
            const resp = await axios.get(getOneUserByID + e,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }  
            })
                .then(function (response) {

                    const SeccionesData = response.data
                    return SeccionesData  
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },

    actualizarFirma: async (id,e,fecha) => {

        try {
            const resp = await axios.put(updateFirma + id, {
                    campoLibre1: e,
                    fechaFirma1:fecha
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },

    actualizarFirma2: async (id,e,fecha) => {

        try {
            const resp = await axios.put(updateFirma + id, {
                    campoLibre2: e,
                    fechaFirma2:fecha
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },

    tokenNuevoUsuario: async () => {

        try {
            const resp = await axios.get(tokenNuevoUsuarioURL)
                .then(function (response) {
                    const tokenPayload = response
                    return tokenPayload.data.payload
                });
            return resp
        } catch (error) {
            console.log(error)
        }
    },

    updateFoto: async (id,foto) => {

        try {
            const resp = await axios.put(updateFirma + id, {
                    imagen: foto
                    
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },


    buscarEquipos: async (e) => {

        try {
            const resp = await axios.post(getAllEquipos, {
                    nombreDeEquipo: e
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },

    buscarEquiposDiv: async (e) => {

        try {
            const resp = await axios.post(getAllEquiposDiv, {
                    division: e
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {

                    const SeccionesData = response.data.payload
                    return SeccionesData
                });

            return resp
        } catch (error) {
            console.log(error)
        }
    },



}