import React from 'react'
import { Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'


const postDeportesURL = URL.deportes[0].postURL
const putDeportesURL = URL.deportes[0].putURL
const token = (sessionStorage.getItem('token'))
const usuario = (sessionStorage.getItem('usuario'))

const CatalogoDeporteForm = (props) => {

    React.useEffect(() => {

        if (props.flag === 1) {
            const dataRowTable = props.dataTable
            //const estadoActivo = dataRowTable.activo === 1 ? true : false

            //setActivo(estadoActivo)
            setNombreDeporte(dataRowTable.nombreDeporte)
       
       
        }
        return () => {
            setValidated({});
        };
    }, []);

    const [guardando, setGuardando] = React.useState(false)
    const [nombreDeporte, setNombreDeporte] = React.useState("")

    



    ////////////////// Validacion de formularios////////////////////////////////
    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {


        } else {
            (props.flag === 0 ? insert() : update())
        }

        setValidated(true);
    };

    ///////////////////////////////////////////////////////////////////////////

    const limpiarCampos = () => {
        return (
           
            setNombreDeporte("")
           
        )

    }

    const insert = async () => {
        setGuardando(true)
        await swal({
            title: "Esta seguro que desea ingresar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "info",
            buttons: true,
            dangerMode: false,
        })
            .then((save) => {
                if (save) {

                    axios.post(postDeportesURL,
                        {
                            nombreDeporte: nombreDeporte,
                            
                        },

                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        .then(function (response) {

                            swal("Equipo Ingresado correctamente!", {
                                icon: "success",
                            });

                            limpiarCampos()

                         
                            setGuardando(false)
                        })
                        .catch(function (error) {
                            swal("Error", {
                                icon: "error",
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }


    const update = async () => {

        setGuardando(true)

        await swal({
            title: "Esta seguro que desea modificar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios.put(putDeportesURL + props.rowTableID,
                        {
                            nombreDeporte: nombreDeporte,
                      
                        },{   headers: {
                            'Authorization': `Bearer ${token}`,
                        }})
                        .then(function (response) {

                            swal("Equipo modificado correctamente!", {
                                icon: "success",
                            });
                            setGuardando(false)
                        })
                        .catch(function (error) {
                            swal("Error", {
                                icon: "error",
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }


    return (
        <div className="col-lg-12">

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" lg="12" controlId="JugadorIdentidad">
                        <Form.Label>Nombre de deporte</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ejemplo: Futboll"
                            required
                            onChange={e => setNombreDeporte(e.target.value)}
                            value={nombreDeporte}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar Nombre de Deporte
                        </Form.Control.Feedback>
                    </Form.Group>

          
                </Row>

                {!nombreDeporte && props.flag === 1 ?
                    <Button variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Cargando...
                    </Button> : guardando ?

                        <Button variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {(props.flag === 0 ? " Guardando..." : " Modificando...")}
                        </Button> : <Button
                            type="submit"
                            variant={(props.flag === 0 ? "primary" : "warning")}>
                            {(props.flag === 0 ? "Crear Deporte" : "Modificar Deporte")}
                        </Button>}

            </Form>

        </div>

    )
}

export default CatalogoDeporteForm
