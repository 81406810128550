import React from 'react'
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { debounce } from "debounce";
import CatalogoEquipoForm from './CatalogoEquipoForm'
import CatalogoEquipoTabla from './CatalogoEquipoTabla';
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'



const getAllEquiposURL = URL.equipos[0].postAllURL
const token = (sessionStorage.getItem('token'))

const CatalogoEquipo = () => {

    const [Equipos, setEquipos] = React.useState([])
    const [sinResultado, setSinResultado] = React.useState(false)

    const buscarEquipos = async (e) => {
        setSinResultado(false)
        setEquipos([])

        await axios.post(getAllEquiposURL, {
            nombreDeEquipo: e
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {
                
                const equiposData = response.data.payload

                !equiposData.length ? setSinResultado(true) :
                    setEquipos(equiposData)
                
            })
            .catch(function (error) {
                swal({
                    title: "ERROR",
                    text: error.message,
                    icon: "error",
                });

                console.error(error)
            })
    }

    return (

        <div>
            <Tabs
                defaultActiveKey="cEquipos"
                onSelect=
                {
                    (eventKey) => {
                        if (eventKey === "eEquipos") {
                           buscarEquipos("")
                        }
                    }

                }
                id="uncontrolled-tab-example"
                className="mb-3"
                transition={true}>

                <Tab eventKey="cEquipos" title="Crear Equipo" >
             
                    <CatalogoEquipoForm
                        flag={0}
                    />

                </Tab>
                <Tab

                    eventKey="eEquipos"
                    title="Buscar y Editar"

                >
                    <div className="container col-12">

                        <div className="input-group mb-3 mt-2">
                            <span
                                className="input-group-text btn btn-dark bi-search"
                                type="button"> Buscar </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar Equipos"
                                aria-label="buscarEquipos"
                                aria-describedby="basic-addon1"
                                onChange={debounce(e =>
                                    buscarEquipos(e.target.value)
                                    , 1000)
                                }
                            />

                        </div>
                    </div>

                    {sinResultado ? <div className="alert alert-danger" role="alert">
                        No se encontraron datos
                    </div> : !Equipos.length ?

                        <Spinner animation="border" variant="primary" />
                        :

                        <CatalogoEquipoTabla
                            EquiposS={Equipos}
                            buscarEquiposS={buscarEquipos}

                        />
                    }

                </Tab>

            </Tabs>

        </div>
    )
}

export default CatalogoEquipo
