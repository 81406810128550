import React from 'react'
import CatalogoDeporte from '../components/Catalogo/CatalogoDeporte.jsx';
const Catalogo = () => {

    React.useEffect(() => {
        const tipoUsuario = (sessionStorage.getItem('tipoUsuario'))
        if(tipoUsuario==2){
            alert("no tienes permisos para estar en este sitio")
            window.location.href = './'
        }
    })
    return (
        <div>
            <CatalogoDeporte />
        </div>
    )
}

export default Catalogo
