import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import "firebase/compat/firestore"

export const app = firebase.initializeApp({
    "projectId": "afhosuscripciones-df678",
    "appId": "1:854906610366:web:e8560c58f8e9d3450374ef",
    "storageBucket": "afhosuscripciones-df678.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyDUyXWybdn06599IQ9orm9fFDdtwGPfFZw",
    "authDomain": "afhosuscripciones-df678.firebaseapp.com",
    "messagingSenderId": "854906610366",
    "measurementId": "G-7XGEVYWZFE"
  });