import React, { Component } from 'react'
import ReportHojaRepresentacion from './ReportHojaRepresentacion'
import ReactToPrint from 'react-to-print';
import { Form, Button, Col, Row, Spinner, Card, Modal } from 'react-bootstrap';
import { PenFill, Printer } from "react-bootstrap-icons";

class ReporteHojaRepresentacionPadre extends Component {

    constructor(props) {
        super(props)
        this.reportRef = React.createRef()
    }
    render() {
        return (
            <div>

                <ReportHojaRepresentacion ref={this.reportRef}
                    firma2={this.props.firma2}
                    nombre={this.props.nombre}
                    fechaNacimiento={this.props.fechaNacimiento}
                    celular={this.props.celular}
                    direccion={this.props.direccion}
                    email={this.props.email}
                    identidad={this.props.identidad}
                    idUser={this.props.idUser}
                    fecha2={this.props.fechaFirma2}
                    division = {this.props.division}
                    encargadoLegalID={this.props.encargadoLegalID}
                    encargadoLegal={this.props.encargadoLegal}
                />
                <div className={this.props.tipoUsuario == 2 ? "invisible" : null}>
                    <ReactToPrint
                        trigger={() =>
                            <div className="d-grid gap-2 m-2">
                                <Button className="mb-3" size="lg" variant="warning">
                                    <Printer /> Imprimir o Descarcar PDF
                                </Button>
                            </div>

                        }
                        content={() => this.reportRef.current}
                    />
                </div>
            </div>
        )
    }
}

export default ReporteHojaRepresentacionPadre