import React from 'react'
const Principal = () => {

    return (

  <div className="container">
      <div className="d-grid gap-2">
      <h1>Principal</h1>
      </div>
  </div>
     
    )
}

export default Principal
