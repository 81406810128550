import React from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../server/endPoints.js'
import { InputGroup, FormControl, Form, Spinner } from 'react-bootstrap';
import db from '../server/axios'
import imagen from '../img/AFHO-01.png'
import imagen2 from '../img/clinicasMedicas.png'


const urlLogin = URL.login[0].postURL
const urlTokenPayload = URL.login[0].tokenURL


const divStyle = {
    width: '500px'
};

const Login = (id) => {
console.log(id.match.params.id)

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
       


        const form = event.currentTarget;
        if (form.checkValidity() === false) {

        } else {
            ingresar()
        }

        setValidated(true);
    };

    const [usuario, setUsuario] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [validated, setValidated] = React.useState(false);
    const [cargando, setCargando] = React.useState(false);
    const [tokenUsuarioNuevo, setTokenUsuarioNuevo] = React.useState(false);
   

    //const logo = 'https://firebasestorage.googleapis.com/v0/b/afhosuscripciones-df678.appspot.com/o/Documentos%2FAFHO-01.png?alt=media&token=bd19ec6e-d641-41fb-85f3-6de2cd7742dc'
    
    const logo = (id.match.params.id === ':1'?imagen:imagen2 )
  
    const ingresar = async () => {

        setCargando(true)
        await axios.post(urlLogin,
            {
                usuario: usuario,
                password: password,
                tipoAfiliado:id.match.params.id
            })
            .then(function (response) {
                const payload = response.data.payload
                sessionStorage.setItem('token', payload)
                
                axios.post(urlTokenPayload,
                    {
                        payload: "Bearer " + payload
                    })
                    .then(function (response) {
                        const payload = response.data.payload.body[0]
                        
                        console.log(response)

                        sessionStorage.setItem('usuario', payload.idUsuario)
                        sessionStorage.setItem('tipoUsuario', payload.idTipoUsuario)
                        sessionStorage.setItem('setYear', payload.setYear)
                        sessionStorage.setItem('imagen', payload.imagen)
                        sessionStorage.setItem('nombre', payload.nombre)
                        sessionStorage.setItem('numeroID', payload.numeroIdentidad)
                        sessionStorage.setItem('usuarioNuevo', 2)
                        sessionStorage.setItem('tipoURL', id.match.params.id)

                        setCargando(false)
                        swal({
                            title: "BIENVENIDO!",
                            text: `${payload.nombre}`,
                            icon: "success",
                            button: "Aceptar",
                        })
                            .then((a) => {
                                if (a) {
                                    window.location.href = payload.idTipoUsuario === 1? `/Jugadores/${id.match.params.id}` : `/nuevoUsuario/${id.match.params.id}`
                                }
                            });
                    })
                    .catch(function (error) {

                        console.log(error)
                    })
            })
            .catch(function (error) {

                swal({
                    title: "Inicio de Sesion!",
                    text: `Usuario o contraseña incorrecta`,
                    icon: "error",
                    button: "Aceptar",
                })
                setCargando(false)
                console.log(error)
            })
    }

    const tokenNuevoUsuario = async () => {

        const tokenData = await db.tokenNuevoUsuario()
        
        sessionStorage.setItem('token', tokenData)
        console.log(tokenData)
        axios.post(urlTokenPayload,
            {
                payload: "Bearer " + tokenData
            })
            .then(function (response) {
                const payload = response.data.payload.body.tipoUSuario
                sessionStorage.setItem('usuarioNuevo', 1)
                sessionStorage.setItem('tipoUsuario', null)
                setCargando(false)
                swal({
                    title: "BIENVENIDO!",
                    text: `A continuacion creara su cuenta`,
                    icon: "success",
                    button: "Aceptar",
                })
                    .then((a) => {
                        if (a) {
                            sessionStorage.setItem('nombre', "Nuevo Usuario")
                            sessionStorage.setItem('imagen', "https://firebasestorage.googleapis.com/v0/b/afhosuscripciones-df678.appspot.com/o/logos%2FnoUSerFound.png?alt=media&token=e339fd86-1d42-499c-8fe8-b5519946ae8f")
                            sessionStorage.setItem('tipoURL', id.match.params.id)
                            window.location.href = `/nuevoUsuario/${id.match.params.id}`
                            
                        }
                    });
            })
            .catch(function (error) {

                console.log(error)
            })
    }

    return (
        <main className="container text-center d-flex flex-column justify-content-center vh-100">

            <div className="row m-5">
                <article className="col-12 m-auto" style={divStyle} >
                    <div className="border bg-dark rounded-3">
                        <img src={logo} alt="" className="img-fluid" />

                        <Form className="m-1" noValidate validated={validated} onSubmit={handleSubmit}>

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <FormControl
                                    placeholder="Numero de Identidad"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={e => setUsuario(e.target.value)}
                                    value={usuario}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Ingrese Identidad
                                </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">*</InputGroup.Text>
                                <FormControl
                                    type='password'
                                    placeholder="contraseña"
                                    aria-label="password"
                                    aria-describedby="basic-addon1"
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Ingrese constraseña
                                </Form.Control.Feedback>
                            </InputGroup>
                            <div className="d-grid gap-2">
                                <button
                                    className="btn btn-primary btn-lg"
                                    type="submit"
                                >
                                    {(cargando ? <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : null)}
                                    {(cargando ? 'Iniciando Sesion...' : "Iniciar Sesion")}</button>
                                <button
                                    className="btn btn-primary btn-lg"
                                    type="button"
                                    onClick={() => tokenNuevoUsuario()} >Crear Cuenta</button>
                            </div>
                        </Form>
                    </div>
                </article>
            </div>
        </main>


    )
}

export default Login
