import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Principal from '../pages/Principal';
import NuevoUsuario from '../pages/NuevoUsuario';
import MenuDeLado from '../components/MenuDeLado';

function App({id}) {
  return (

    <BrowserRouter>
  
      <Switch>
        
        <Route
      exact
      path="/:id"
      render={(props) => <Login {...props} id={id} />}
    />
        <MenuDeLado/>
        <Route exact path= {`/Principal/:${id}`} component= {Principal}/> 
        <Route exact path={`/nuevoUsuario/:${id}`}  component= {NuevoUsuario}/>   
      </Switch>
    </BrowserRouter>
  );
}

export default App;
