import React from 'react'
import { Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
import URL from '../../server/endPoints.js'


const postJugadorURl = URL.equipos[0].postURL
const putJugadorsURL = URL.equipos[0].putURL
const token = (sessionStorage.getItem('token'))
const usuario = (sessionStorage.getItem('usuario'))

const tipoUsuario = (sessionStorage.getItem('tipoUsuario'))



const CatalogoEquipoForm = (props) => {

    React.useEffect(() => {

        if (props.flag === 1) {
            const dataRowTable = props.dataTable
            const estadoActivo = dataRowTable.activo === 1 ? true : false

            setActivo(estadoActivo)
            setNombreEquipo(dataRowTable.nombreDeEquipo)
            setDivision(dataRowTable.division)
       
        }
        return () => {
            setValidated({});
        };
    }, []);

    const [guardando, setGuardando] = React.useState(false)
    const [activo, setActivo] = React.useState(true)
    const [numeroCuenta, setNumeroCuenta] = React.useState("")
    const [nombreEquipo, setNombreEquipo] = React.useState("")
    const [division, setDivision] = React.useState("")


    



    ////////////////// Validacion de formularios////////////////////////////////
    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {


        } else {
            (props.flag === 0 ? insert() : update())
        }

        setValidated(true);
    };

    ///////////////////////////////////////////////////////////////////////////

    const limpiarCampos = () => {
        return (
            setActivo(true),
            setNombreEquipo("")
           
        )

    }



    const insert = async () => {
        setGuardando(true)
        await swal({
            title: "Esta seguro que desea ingresar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "info",
            buttons: true,
            dangerMode: false,
        })
            .then((save) => {
                if (save) {

                    axios.post(postJugadorURl,
                        {
                            nombreDeEquipo: nombreEquipo,
                            division: division
                        },

                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        .then(function (response) {

                            swal("Equipo Ingresado correctamente!", {
                                icon: "success",
                            });

                            limpiarCampos()

                            setNumeroCuenta("")
                            setGuardando(false)
                        })
                        .catch(function (error) {
                            swal("Error", {
                                icon: "error",
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }


    const update = async () => {

        setGuardando(true)

        await swal({
            title: "Esta seguro que desea modificar esta informacion",
            text: "al darle aceptar esperar unos segundos a que aparezca el mensaje de confirmacion",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios.put(putJugadorsURL + props.rowTableID,
                        {
                            nombreDeEquipo: nombreEquipo,
                            division: division
                        },{   headers: {
                            'Authorization': `Bearer ${token}`,
                        }})
                        .then(function (response) {

                            swal("Equipo modificado correctamente!", {
                                icon: "success",
                            });
                            setGuardando(false)
                        })
                        .catch(function (error) {
                            swal("Error", {
                                icon: "error",
                            });
                            setGuardando(false)
                            console.log(error)
                        })

                } else {
                    setGuardando(false)
                }
            });
    }


    return (
        <div className="col-lg-12">

            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Row className="mb-3">
                    <Form.Group as={Col} md="12" lg="12" controlId="JugadorIdentidad">
                        <Form.Label>Nombre de equipo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ejemplo: FC Barcelona"
                            required
                            onChange={e => setNombreEquipo(e.target.value)}
                            value={nombreEquipo}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresar Nombre de equipo
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" lg="12" controlId="division">
                        <Form.Label>division</Form.Label>

                        <Form.Select
                            aria-label="divisionSelect"
                            value={division}
                            onChange={e => setDivision(e.target.value)}
                        >
                            <option>Seleccione un division</option>
                            <option value={1}>Primera</option>
                            <option value={2}>Segunda</option>
                            <option value={3}>Reservas</option>
                            <option value={4}>Amateur</option>
                            <option value={5}>Femenino</option>

                        </Form.Select>
                    </Form.Group>
                </Row>

                {!nombreEquipo && props.flag === 1 ?
                    <Button variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Cargando...
                    </Button> : guardando ?

                        <Button variant={(props.flag === 0 ? "primary" : "warning")} disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {(props.flag === 0 ? " Guardando..." : " Modificando...")}
                        </Button> : <Button
                            type="submit"
                            variant={(props.flag === 0 ? "primary" : "warning")}>
                            {(props.flag === 0 ? "Crear Equipo" : "Modificar Equipo")}
                        </Button>}

            </Form>

        </div>

    )
}

export default CatalogoEquipoForm
