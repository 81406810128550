import React, { useRef } from 'react'
import { Render } from 'react-dom'
import { Container, Button, Col, Row, Spinner, Card, Modal,Form } from 'react-bootstrap';
import { Border, PenFill } from "react-bootstrap-icons";
import CanvasDraw from 'react-canvas-draw'
import db from '../../server/axios'
import swal from 'sweetalert';
import imagen from '../../img/firma.jpg'

const ReporteClinicasParaDeportistasMenorEdad = React.forwardRef((p, ref) => {
  const existeFirma = p.firma

  React.useEffect(() => {

    if (existeFirma) {
      firmaDrawDocumento.current.loadSaveData(p.firma)
      setFechaFirma(p.fecha)
      setVisible('invisible')

    } else {
      setVisible('visible')
      //setFechaFirma(new Date())
      fecha()
    }

    const fecha2 = p.fechaFirma


    if (existeFirma) {
      const dia = fecha2.split('-')
      setVisible("invisible")

      switch (dia[1]) {
        case "01":
          setMes("Enero");
          break;
        case "02":
          setMes("Febrero");
          break;
        case "03":
          setMes("Marzo");
          break;
        case "04":
          setMes("Abril");
          break;
        case "05":
          setMes("Mayo");
          break;
        case "06":
          setMes("Junio");
          break;
        case "07":
          setMes("Julio");
          break;
        case "08":
          setMes("Agosto");
          break;
        case "09":
          setMes("Septiembre");
          break;
        case "10":
          setMes("Octubre");
          break;
        case "11":
          setMes("Noviembre");
          break;
        case "12":
          setMes("Diciembre");

      }
      setDia(dia[2])
      setYear(dia[0])

    } else {
      fecha()
      setVisible("visible")
    }


    


  })
  const [show, setShow] = React.useState(false);
  const [firma, setFirma] = React.useState("");
  const [fechaFirma, setFechaFirma] = React.useState("");
  const [cargando, setCargando] = React.useState(false);
  const [visible, setVisible] = React.useState("");
  const [dia, setDia] = React.useState("");
  const [mes, setMes] = React.useState("");
  const [year, setYear] = React.useState("");
  const logo = imagen
  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true)
  };

  const firmaDraw = useRef(null)
  const firmaDrawDocumento = useRef(null)

  const handleClickGuardar = async () => {

    swal({
      title: "Esta Seguro que desea guardar?",
      text: "Una vez guardado no se podra modificar el archivo.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (save) => {
        if (save) {
          setCargando(true)
          const data = firmaDraw.current.getSaveData();
          setFirma(data)

          await updateFirma(p.idUser, data, fechaFirma)
          firmaDrawDocumento.current.loadSaveData(data)
          setCargando(false)
          swal("Documento Firmado Correctamente!", {
            icon: "success",
          });
          handleClose()
        }
      });
  }

  const updateFirma = async (id, data, date) => {

    await db.actualizarFirma2(id, data, date)

  }


  const clear = () => {
    firmaDraw.current.clear()
  }


  const fecha = () => {

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setFechaFirma(date)
    const dia = date.split('-')

    switch (dia[1]) {
      case "01":
        setMes("Enero");
        break;
      case "02":
        setMes("Febrero");
        break;
      case "03":
        setMes("Marzo");
        break;
      case "04":
        setMes("Abril");
        break;
      case "05":
        setMes("Mayo");
        break;
      case "06":
        setMes("Junio");
        break;
      case "07":
        setMes("Julio");
        break;
      case "08":
        setMes("Agosto");
        break;
      case "09":
        setMes("Septiembre");
        break;
      case "10":
        setMes("Octubre");
        break;
      case "11":
        setMes("Noviembre");
        break;
      case "12":
        setMes("Diciembre");

    }
    setDia(dia[2])
    setYear(dia[0])
  }


  return (

    <div>
      <Container className='col-lg-11 mx-auto' ref={ref}>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>firma</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <CanvasDraw
              style={{ border: "solid" }}
              brushRadius={1}
              brushColor={"#000000"}
              ref={firmaDraw}
              canvasWidth={300}
              canvasHeight={300}
              lazyRadius={0}
            />
          </Modal.Body>
          <Modal.Footer>

            <Button

              variant="success"
              onClick={handleClickGuardar}>

              {(cargando ? <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : null)}

              {cargando ? " Guardando..." : " Guardar"}

            </Button>

            <Button variant="warning" onClick={clear}>
              Limpiar
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>


        <style type="text/css" media="print">
          {"\
            @page { size: Letter; margin: 50px 50px 50px 50px}\
      "}</style>

        <Row className='align-middle'>
          <Col>
            <Card
              style={{ width: '150px', height: '150px', border: "none" }}

            >
              <Card.Img variant="top" src={"https://firebasestorage.googleapis.com/v0/b/afhosuscripciones-df678.appspot.com/o/Documentos%2FAFHO-01.png?alt=media&token=bd19ec6e-d641-41fb-85f3-6de2cd7742dc"} />
            </Card>
          </Col>

          <Col className="text-center col-lg-6"><h4>ASOCIACION DE FUTBOLISTAS DE HONDURAS (AFHO)</h4></Col>
          <Col>

           

          </Col>

        </Row>

        <Row className="">
          <Col>
            <p className='mt-2'>CONTRATO DE PRESTACION DE SERVICIOS PROFESIONALES</p>
            <p className='text-justify' style={{ textAlign: "justify" }}>Nosotros, <strong>CARLOS ALVARADO</strong> , mayor de edad, casado, actuando en mi condición de Presidente de la <strong> ASOCIACION DE FUTBOLISTAS DE HONDURAS (AFHO)</strong>, con Personería Jurídica numero 756–2012, otorgada por la Secretaria de Gobernación y Justicia en fecha 20 de agosto del 2012 e inscrita en el Registro de Sentencias bajo el numero catorce (14) del Tomo Trescientos Sesenta y Cuatro (364) del Registro de la Propiedad de Tegucigalpa, quien en adelante y para fines del presente contrato, se denominara <strong>LA ASOCIACION</strong> y <strong> <u>{p.encargadoLegal}</u></strong>  mayor de edad, con Documento Nacional de Identificación número <strong> <u>{p.encargadoLegalID} </u></strong>, quien actúa en su condición de representante legal del menor <strong><u>{p.nombre}</u></strong>, quien pertenece a las ligas menores de futbol de Honduras, por medio del presente hemos convenido celebrar como al efecto lo hacemos, contrato de prestación de servicios, el cual se regirá por las clausulas y condiciones siguientes:</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>CONSIDERANDO:</strong> Que <strong>LA ASOCIACION</strong> brinda los servicios de Medicina General, Odontología, Fisioterapia, Hidroterapia, Psicología, Nutricionista, y Atenciones Legales como parte de su función de contribuir positivamente al desarrollo integral y formación profesional de los futbolistas y de todas aquellas personas que practican el futbol en general como parte de los fines que tiene <strong>LA ASOCIACION</strong> según sus Estatutos.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>CONSIDERANDO:</strong> Que dadas las necesidades que tienen los menores de recibir las atenciones de Medicina General, Odontología, Fisioterapia, Hidroterapia, Psicología, Nutrición y Atenciones Legales, se celebra este contrato bajo las condiciones y clausulas siguientes:</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>PRIMERO:</strong> Que <strong>LA ASOCIACION</strong> brindara los servicios de clínicas integrales en salud en las áreas de Medicina General, Odontología, Fisioterapia, Hidroterapia, Psicología, Nutricionista y Gimnasio. Asimismo, brindara las Atenciones Legales, todas las atenciones serán atendidas en la Ciudad de Tegucigalpa, Colonia Alameda, Avenida Julio Lozano Diaz frente a Save The Children.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>SEGUNDO:</strong> Que para gozar del beneficio de la atención que brinda <strong>LA ASOCIACION</strong>, es necesario que el menor <strong><u>{p.nombre}</u></strong> sea afiliado, quien debe ser representado por sus padres y cumpliendo con los requisitos exigidos por <strong>LA ASOCIACION</strong>, los cuales son: 1.- Acta de Nacimiento Original del menor, 2.- Carnet de vacunas del menor, 3.- Carnet de vacunas contra el Covid19, tanto del menor como de los padres y 4.- Fotocopia de Documento Nacional de Identificación de los padres.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>TERCERO: </strong>Cada padre de familia en representación del menor afiliado, deberá llenar el formulario respectivo de Afiliación, el cual pondrá a su disposición <strong>LA ASOCIACION</strong>.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>CUARTO:</strong> El padre de familia, en representación del menor <strong><u>{p.encargadoLegal}</u></strong> , debe cancelar la cantidad de <strong>DOSCIENTOS LEMPIRAS MENSUALES</strong> (L. 200.00); los cuales podrán ser cancelados ya sea mensual, bimestral, trimestral o anualmente y deben ser cancelados el día cinco (5) de cada mes. Este valor le da el derecho al menor de recibir atenciones en Medicina General, Odontología, Fisioterapia, Hidroterapia, Psicología, Nutricionista y Atenciones Legales. En caso de no estar solvente, no podrá recibir las atenciones referidas hasta que realice la cancelación de las cuotas que estén vencidas. Dicha cantidad no será reembolsable en el caso de que el menor <strong><u>{p.nombre}</u></strong> no utilice los servicios de las atenciones que <strong>LA ASOCIACION</strong> ofrece.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>QUINTO: El(la) señor(a) <strong><u>{p.encargadoLegal}</u></strong>, en la condición con que actúa, manifiesta que esta de acuerdo con los requisitos establecidos para la afiliación de su menor hijo <strong><u>{p.nombre}</u></strong> y que se compromete a cancelar puntualmente el valor de y por las atenciones descritas en la clausula cuarta de este contrato.</strong></p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>SEXTO:</strong>El presente contrato tendrá vigencia por un ano a partir de la fecha en que sea suscrito por las partes.</p>
            <p className='text-justify' style={{ textAlign: "justify" }}> <strong>SEPTIMO:</strong>SEPTIMO: Ambos comparecientes manifiestan estar de acuerdo en lo establecido en este contrato y que, en caso de surgir una discrepancia, se someten al proceso de conciliación y arbitraje.  Para lo cual firman el presente contrato a los <strong><u>{dia}</u></strong> días del mes de <strong><u>{mes}</u></strong> del año <strong><u>{year}</u></strong>.</p>
          </Col>
        </Row>


        <Row >
      
      <Col  md="12" lg="12">
        
        <Row >
          <img
            className='mx-auto'
            src={logo}
            style={{ maxWidth: '12rem' }} />
        </Row>
        <Row className='text-center'><Form.Label>______________________</Form.Label></Row>
        <Row className='text-center'> <Form.Label>Carlos Alvarado</Form.Label></Row>
        <Row className='text-center'> <Form.Label>Presidente AFHO</Form.Label></Row>

      </Col>

     

      <Col className='text-center' md="12" lg="12">
        <Row className='text-center'>
          <CanvasDraw
            className='mx-auto'
            ref={firmaDrawDocumento}
            canvasWidth={100}
            canvasHeight={100}
            disabled={true}
            hideGrid={true}
            immediateLoading={false} 
          />
        </Row>
        <Row><Form.Label>______________________</Form.Label></Row>
        <Row> <Form.Label>{p.encargadoLegal}</Form.Label></Row>
        

      </Col>
    </Row>

        

      </Container>



      <div className="d-grid gap-2 m-2">
        <Button className={visible} size="lg" variant="dark" onClick={() => [handleShow()]}>
          <PenFill /> Firmar
        </Button>
     
      </div>

    </div>

  )
}
)
export default ReporteClinicasParaDeportistasMenorEdad